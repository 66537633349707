/* You can add global styles to this file, and also import other style files */
// body {
//     .arrow-down {
//         width: 0;
//         height: 0;
//         border-left: 8px solid transparent;
//         border-right: 8px solid transparent;

//         border-top: 8px solid;
//     }

//     .floatleft {
//         float: left;
//     }
// }

@import '../node_modules/@arc/arc-shared-frontend/src/assets/@arc/theme/theme-bt.scss';
// @import '~@arc/account-frontend/assets/@arc/sass/arc-overrides/index';
// @import '~@arc/arc-collections-frontend/assets/@arc/sass/arc-overrides/index';
// @import '~@arc/invoicing-frontend/assets/@arc/sass/arc-overrides/index';
// @import '~@arc/arc-configuration-frontend/assets/@arc/sass/arc-overrides/index';
// @import '~@arc/arc-user-frontend/assets/@arc/sass/arc-overrides/index';
// @import '~@arc/arc-cashapp-frontend/assets/@arc/sass/arc-overrides/index';
// @import '~@arc/arc-creditapp-frontend/assets/@arc/sass/arc-overrides/global';
// @import '~@arc/arc-credit-common/assets/@arc/sass/arc-overrides/global';
// @import '~@arc/card-merchant-registration/assets/@arc/arc-overrides/index';
// @import '~@arc/search-frontend/assets/@arc/sass/arc-overrides/index';

/* You can add global styles to this file, and also import other style files */

p-dropdown {
  width: auto;
}
/* in-flight clone */
.gu-mirror {
  cursor: ns-resize !important;
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;

  .drag-handle {
    cursor: ns-resize !important;
  }
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

.p-progressbar .p-progressbar-value {
  background: $primary-color;
}

.p-progressbar {
  height: 3px;
  border-radius: 6px;
}

arc-shared-login-widget-ultimate {
  min-width: 0px !important;
}

arc-shared-login-widget-ultimate fieldset {
  border: 0px;
}

p-footer {
  padding: $large-element-spacing !important;
  border-top: $subtle-border;
  margin-top: $small-element-spacing;
}

/* Overrides for p-calendar */
.p-datepicker {
  td > span {
    &:hover {
      border-radius: 50%;
      background-color: $accent-color;
      @include contrast-text($accent-color);
    }
  }

  &--errors {
    margin-top: 25px;
  }
}

.adyen-checkout__button--pay {
  background-color: $primary-color;
}
.adyen-checkout__button--pay:hover {
  background-color: $primary-color;
  filter: brightness(85%);
}

.adyen-checkout__payment-method--selected {
  background: $body-bg-color;
  border: 1px solid rgb(77 96 105 / 40%);
}
.adyen-checkout__label__text {
  color: #4d6069;
  border: 1px solid rgb(77 96 105 / 40%);
  border-bottom: 0;
  padding: 4px 8px;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.adyen-checkout__radio_group__label {
  border: none;
  padding: 0 0 0 24px;
}
.adyen-checkout__input {
  border: 1px solid rgb(77 96 105 / 40%);
  border-top-style: dashed;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.adyen-checkout__dropdown__button {
  border-top-style: dashed;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.adyen-checkout__button {
  background: $primary-color;
}
.adyen-checkout__button:hover {
  background-color: $primary-color;
  filter: brightness(85%);
}
.adyen-checkout__payment-method {
  border: 1px solid rgb(77 96 105 / 40%);
}
